import styled from "styled-components"

import { EmptyState } from "src/components/EmptyState"
import { useTranslate } from "src/i18n/useTranslate"
import SearchIcon from "src/ui/icons/search-no-results.svg"
import { spacing } from "src/ui/spacing"

export function HomeLogFilteredEmptyState() {
  const { t, langKeys } = useTranslate()
  return (
    <EmptyStateBox>
      <EmptyState
        icon={
          <SearchIconBox>
            <SearchIcon width={60} height={60} />
          </SearchIconBox>
        }
        title={t(langKeys.search_nothing_found)}
      />
    </EmptyStateBox>
  )
}

const EmptyStateBox = styled.div`
  margin-top: ${spacing.XL6};
`

const SearchIconBox = styled.div`
  background-color: #f6f9fc;
  border-radius: 50%;
  padding: ${spacing.L};
`
