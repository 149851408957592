import {
  TLogFilterContext,
  TTimePeriod,
  usePostDateShortcutSelected,
  usePostLogFilterApplied,
  usePostLogFilterCleared,
  usePostLogFilterInitiated,
} from "src/data/analytics/queries/logAnalyticsQueries"
import { PresetKey } from "src/ui/DateRangePicker/Calendar"
import {
  DateRangePicker,
  DateRangePickerProps,
  TDateRange,
} from "src/ui/DateRangePicker/DateRangePicker"

type TDateRangePicker = Pick<
  DateRangePickerProps,
  "startDate" | "endDate" | "dateRangeProps"
>

export function HomeLogDateRangePicker({
  startDate,
  endDate,
  setDateRange,
  dateRangeProps,
  setOffset,
  metaData,
}: TDateRangePicker & {
  setDateRange: (dateRange: TDateRange) => void
  setOffset: (offset: number) => void
  metaData?: { context: TLogFilterContext }
}) {
  const postLogFilterApplied = usePostLogFilterApplied()
  const postDateShortcutSelected = usePostDateShortcutSelected()
  const postLogFilterCleared = usePostLogFilterCleared()
  const postLogFilterInitiated = usePostLogFilterInitiated()

  function handleSelectPreset({
    presetKey,
    clearSelection,
  }: {
    presetKey: PresetKey
    clearSelection?: boolean
  }) {
    function transformPreset(): TTimePeriod {
      if (presetKey === "DAY") {
        return "last_day"
      }
      if (presetKey === "WEEK") {
        return "last_week"
      }
      if (presetKey === "MONTH") {
        return "last_month"
      }
      return "last_year"
    }

    if (metaData?.context) {
      postDateShortcutSelected.mutate({
        period: transformPreset(),
        context: metaData.context,
      })
    }

    if (clearSelection && metaData?.context) {
      postLogFilterCleared.mutate({
        filter_type: "date",
        context: metaData.context,
      })
    }
  }

  function handleDateRangeChange(dateRange: TDateRange) {
    setOffset(0)
    setDateRange(dateRange)

    if (metaData?.context) {
      postLogFilterApplied.mutate({
        filter_type: "date",
        context: metaData.context,
      })
    }
  }

  function handleButtonClick() {
    if (metaData?.context) {
      postLogFilterInitiated.mutate({
        filter_type: "date",
        context: metaData.context,
      })
    }
  }

  return (
    <DateRangePicker
      startDate={startDate}
      endDate={endDate}
      onDateRangeChange={handleDateRangeChange}
      dateRangeProps={dateRangeProps}
      onSelectPreset={handleSelectPreset}
      onButtonClick={handleButtonClick}
    />
  )
}
