import { useMemo, useState } from "react"

import {
  TLogFilterContext,
  usePostLogFilterApplied,
  usePostLogFilterCleared,
  usePostLogFilterEventSearched,
  usePostLogFilterInitiated,
} from "src/data/analytics/queries/logAnalyticsQueries"
import { homeEventFilterOptions } from "src/data/events/logic/homeEventConstants"
import { THomeEventFilterGroup } from "src/data/events/types/homeEventTypes"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useFetchFlags } from "src/data/flags/flagsQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { DropdownMultiSelect } from "src/ui/DropdownSelect/DropdownMultiSelect"
import { TSelectOption } from "src/ui/DropdownSelect/DropdownSelect"

type HomeLogEventFilterDropdownProps = {
  loading?: boolean
  eventFilter: THomeEventFilterGroup[]
  setEventFilter: React.Dispatch<React.SetStateAction<THomeEventFilterGroup[]>>
  metaData?: { context: TLogFilterContext }
}

export function HomeLogEventFilterDropdown({
  loading,
  eventFilter,
  setEventFilter,
  metaData,
}: HomeLogEventFilterDropdownProps) {
  const { _t, langKeys } = useTranslate()
  const { orgId } = useOrganization()

  const cigaretteSmokeAvailable = useFeatureAvailability({
    feature: "cigarette_smoke_detection",
  }).available
  const sensorSwapAvailable = useFeatureAvailability({
    feature: "sensor_swap",
  }).available

  const fetchFlags = useFetchFlags({ organization_id: orgId })
  const backendFlags = fetchFlags.data

  const [searchValue, setSearchValue] = useState("")

  const postLogFilterApplied = usePostLogFilterApplied()
  const postLogFilterEventSearched = usePostLogFilterEventSearched()
  const postLogFilterCleared = usePostLogFilterCleared()
  const postLogFilterInitiated = usePostLogFilterInitiated()

  const availableFilterGroups = useMemo(() => {
    // Limiting to 30 events in the events parameter so that
    // we do not exceed the BE character limit on the events parameter
    const canAddAdditionalFilter = eventFilter.length < 30
    const isChecked = (value: THomeEventFilterGroup) =>
      eventFilter.includes(value)

    return homeEventFilterOptions
      .filter((option) => {
        if (option.value === "smoking_detection") {
          return cigaretteSmokeAvailable
        }
        if (option.value === "sensor_swap") {
          return sensorSwapAvailable
        }
        if (option.value === "fire_and_co") {
          return !!backendFlags?.eureka_released
        }
        if (option.value === "water_leak") {
          return !!backendFlags?.water_leak_detection_released
        }
        return true
      })
      .map((e) => ({
        value: e.value,
        label: _t(e.label),
        disabled: !canAddAdditionalFilter && !isChecked(e.value),
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [
    _t,
    backendFlags?.eureka_released,
    backendFlags?.water_leak_detection_released,
    cigaretteSmokeAvailable,
    eventFilter,
    sensorSwapAvailable,
  ])

  const dropdownOptions = useMemo(() => {
    return availableFilterGroups.filter((option) =>
      option.label.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
    )
  }, [searchValue, availableFilterGroups])

  function handleClearSelection() {
    setEventFilter([])

    if (metaData?.context) {
      postLogFilterCleared.mutate({
        filter_type: "event",
        context: metaData.context,
      })
    }
  }

  function handleOnClick() {
    if (metaData?.context) {
      postLogFilterInitiated.mutate({
        filter_type: "event",
        context: metaData.context,
      })
    }
  }

  function handleChange({
    checked,
    option,
  }: {
    checked: boolean
    option: TSelectOption<THomeEventFilterGroup>
  }) {
    if (checked) {
      setEventFilter((prev) => [...prev, option.value])

      if (metaData?.context) {
        postLogFilterApplied.mutate({
          filter_type: "event",
          event_type: option.value,
          context: metaData.context,
        })
      }
    } else {
      setEventFilter((prev) => prev.filter((o) => o !== option.value))
    }
  }

  function handleSearch(searchValue: string) {
    setSearchValue(searchValue)

    if (!!searchValue && metaData?.context) {
      postLogFilterEventSearched.mutate({
        search_term: searchValue,
        context: metaData.context,
      })
    }
  }

  return (
    <DropdownMultiSelect
      label={_t(langKeys.filter_event_type)}
      placeholder={_t(langKeys.search)}
      options={dropdownOptions}
      selectedValues={eventFilter}
      onChange={handleChange}
      onSearch={handleSearch}
      onClearSelection={handleClearSelection}
      loading={loading}
      onClick={handleOnClick}
    />
  )
}
