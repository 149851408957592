import { usePostTrackEventWithParams } from "src/data/analytics/queries/analyticsQueries"
import { THomeEventFilterGroup } from "src/data/events/types/homeEventTypes"

export type TLogFilterContext = "home_log" | "home_log_global"
export type TTimePeriod = "last_day" | "last_week" | "last_month" | "last_year"

type TLogFilterTypes =
  | "Log Filter Applied"
  | "Log Filter Home Searched"
  | "Log Filter Event Searched"
  | "Log Filter Date Shortcut Selected"
  | "Log Filter Cleared"
  | "Log Filter Initiated"

type TFilterType = "home" | "event" | "date"

type TPostLogFilterEventType =
  | {
      filter_type: TFilterType
      num_of_homes: number
      context: TLogFilterContext
    }
  | {
      filter_type: TFilterType
      event_type: THomeEventFilterGroup
      context: TLogFilterContext
    }
  | {
      filter_type: TFilterType
      context: TLogFilterContext
    }

function usePostLogFilterEvent<TEventProperties>(event: TLogFilterTypes) {
  return usePostTrackEventWithParams<TLogFilterTypes, TEventProperties>({
    event,
  })
}

export function usePostLogFilterApplied() {
  return usePostLogFilterEvent<TPostLogFilterEventType>("Log Filter Applied")
}

export function usePostLogFilterHomeSearched() {
  return usePostLogFilterEvent<{ search_term: string; context: string }>(
    "Log Filter Home Searched"
  )
}

export function usePostLogFilterEventSearched() {
  return usePostLogFilterEvent<{ search_term: string; context: string }>(
    "Log Filter Event Searched"
  )
}

export function usePostDateShortcutSelected() {
  return usePostLogFilterEvent<{ period: TTimePeriod; context: string }>(
    "Log Filter Date Shortcut Selected"
  )
}

export function usePostLogFilterCleared() {
  return usePostLogFilterEvent<{ filter_type: TFilterType; context: string }>(
    "Log Filter Cleared"
  )
}

export function usePostLogFilterInitiated() {
  return usePostLogFilterEvent<{ filter_type: TFilterType; context: string }>(
    "Log Filter Initiated"
  )
}
